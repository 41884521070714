import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SideNavbar from "../components/SideNavbar";
import PageTemplatesIntro from "../components/PageTemplatesIntro";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";
import "../index.css";
import "./Pages.css";
import onedealfig from "../assets/onedealfig.png";
import solution from "../assets/onedeal/solution.png";

const OneDeal = () => {
  const templateDataonedeal =
    PageTemplatesIntroData[PageTemplatesIntroData.length - 2];
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/OneDeal", // Replace with your custom path
      title: "OneDeal", // Replace with your custom title
    });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="onedeal">
        <SideNavbar />
        <PageTemplatesIntro
          title={templateDataonedeal.title}
          headimg={templateDataonedeal.headimg}
          projectType={templateDataonedeal.projectType}
          role={templateDataonedeal.role}
          team={templateDataonedeal.team}
          contribution={templateDataonedeal.contribution}
          tools={templateDataonedeal.tools}
          intro={templateDataonedeal.intro}
        />

        <div className="onedeal template-global container">
          <a
            style={{
              textDecoration: "none",
              color: "var(--color)",
              fontSize: "1.2rem",
            }}
            href="https://onedeal.biz/"
            target="_blank"
          >
            Visit the website
          </a>
          <section>
            <h2>Overview</h2>
            <hr className="separator"></hr>
            <h3>Context</h3>
            <p>
              Onedeal, a SaaS startup, is an end to end solution that takes
              investors through the entire small and mid-size business
              acquisition process from beginning to end. We provide a
              marketplace, a suite of tools and financing, so they can close
              their business acquisition quickly and efficiently.
            </p>
            <h3>My role</h3>
            <p>
              As there is only one with a design background in the team, me, so
              I took charge of:<br></br>
              <br></br>- Be responsible for improving the searching experience
              by <b> redesigning components</b> using Figma
              <br></br>- Made prototypes of <b> web UX/UI design</b> in Figma
              and communicated with the team<br></br>- Realized the design with
              HTML, CSS and React.js after iterations were done.<br></br>
            </p>
            <h3>Goals</h3>
            <p>
              - Facilitate UX design sessions aimed at pinpointing and serving
              our designated user groups,crafting a unique UX that
              <b> aligns with both user requirements and brand essence.</b>
              <br></br>- Completely redesign the website's user interface to
              elevate the acquisition experience for users, while ensuring
              alignment with <b> the business objectives</b> .
            </p>
            <img
              style={{ margin: "2rem 0" }}
              loading="lazy"
              src={onedealfig}
              alt=""
            ></img>
            <h3>Solution</h3>
            <img
              style={{ margin: "2rem 0" }}
              loading="lazy"
              src={solution}
              alt="solution"
            ></img>
            <div style={{ textAlign: "center" }}>
              {" "}
              <a
                style={{
                  textDecoration: "none",
                  color: "var(--color)",
                  fontSize: "1.2rem",
                }}
                href="https://onedeal.biz/"
                target="_blank"
              >
                Visit the website
              </a>
            </div>

            <h3>Impact</h3>
            <p>- Launched in 3 months</p>
            <p>- A great increasing from user ratings of the new website</p>
          </section>
        </div>

        <div className="onedeal template-global container"></div>
      </div>
      <Footer />
    </div>
  );
};

export default OneDeal;
