import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Comparison from "../components/Comparison";
import SideNavbar from "../components/SideNavbar";
import "../index.css";
import "./Pages.css";
import PageTemplatesIntro from "../components/PageTemplatesIntro";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";
import stakeholder from "../assets/passioncy/stakeholders.png";
import storyboard1 from "../assets/passioncy/storyboard1.webp";
import storyboard2 from "../assets/passioncy/storyboard2.png";
import storyboard3 from "../assets/passioncy/storyboard3.png";
import comp3 from "../assets/passioncy/comp1.png";
import comp4 from "../assets/passioncy/comp2.png";
import comp5 from "../assets/passioncy/comp5.png";
import comp6 from "../assets/passioncy/comp6.png";
import comp7 from "../assets/passioncy/comp7.png";
import comp8 from "../assets/passioncy/comp8.png";

import goals from "../assets/passioncy/goals.webp";
import infoarch from "../assets/passioncy/infoarch.webp";
import comp1 from "../assets/passioncy/1.webp";
import comp2 from "../assets/passioncy/2.webp";
import result1 from "../assets/passioncy/result1.webp";
import result2 from "../assets/passioncy/result2.webp";
import result3 from "../assets/passioncy/result3.webp";
import result4 from "../assets/passioncy/result4.gif";
import { React, useEffect } from "react";

import ReactGA from "react-ga4";

const Passioncy = () => {
  const templateDataPassioncy = PageTemplatesIntroData[2];

  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Passioncy", // Replace with your custom path
      title: "Passioncy", // Replace with your custom title
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="passioncy">
        <SideNavbar />
      </div>
      <PageTemplatesIntro
        title={templateDataPassioncy.title}
        headimg={templateDataPassioncy.headimg}
        projectType={templateDataPassioncy.projectType}
        role={templateDataPassioncy.role}
        team={templateDataPassioncy.team}
        contribution={templateDataPassioncy.contribution}
        tools={templateDataPassioncy.tools}
        intro={templateDataPassioncy.intro}
      />
      <div className="passioncy template-global container">
        <section>
          <h2>Overview</h2>
          <hr className="separator"></hr>
          <h3>Context</h3>
          <p>
            Customers love watching videos while shopping because they find them
            visually appealing, entertaining, and informative. Videos build
            authenticity, trust, and social proof, making the shopping
            experience enjoyable and influencing purchasing decisions. Brands
            leverage videos to educate, personalize, and create compelling
            narratives for better customer satisfaction.
          </p>
          <div
            className="data"
            style={{
              margin: "2rem 0",
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "6rem",
                  fontWeight: "700",
                  color: "var(--color)",
                  margin: "4rem",
                }}
              >
                94%
              </p>
              <p>
                of people have watched an explainer video to learn more about a
                product or service
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: "6rem",
                  fontWeight: "700",
                  color: "var(--color)",
                  margin: "4rem",
                }}
              >
                84%
              </p>
              <p>
                of consumers surveyed were convinced to buy a product or service
                after watching a brand’s video
              </p>
            </div>
          </div>
        </section>
        <section>
          <h3>Stakeholders</h3>
          <img loading="lazy" src={stakeholder} alt="stakeholder"></img>
          <h3>Challenges</h3>
          <p>
            Firstly, we need to ensure that the increased traffic effectively
            translates into meaningful business outcomes by optimizing
            conversion rates. Secondly, justifying the investment in the
            redesign becomes crucial for the success of the project. Sustaining
            long-term traffic growth is an ongoing goal that requires continuous
            efforts in marketing and engagement.
          </p>
          <h3>Solution</h3>
          <p>
            The dashboard of the business side helps to find qualified
            influencers, to monitor the creation quality and keep communication
            fluent.
          </p>
        </section>
      </div>
      <div
        style={{
          marginTop: "4rem",
          padding: "48% 0 0 0",
          position: "relative",
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/852944505?badge=0&autopause=0&player_id=0&app_id=58479&muted=1&autoplay=1&loop=1"
          frameBorder={0}
          allow="autoplay; fullscreen; picture-in-picture"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="8月9日"
        />
      </div>

      <div className="passioncy template-global container">
        <section>
          <h3> Impact </h3>
          <div className="data">
            <div>
              <p
                style={{
                  fontSize: "6rem",
                  fontWeight: "700",
                  color: "var(--color)",
                  margin: "4rem",
                }}
              >
                217%
              </p>
              <p>achieved an impressive 217% increase in user engagement</p>
            </div>
            <div>
              <p
                style={{
                  fontSize: "6rem",
                  fontWeight: "700",
                  color: "var(--color)",
                  margin: "4rem",
                }}
              >
                5%
              </p>
              <p>
                conversion rate has shown a remarkable increase of 5%,
                indicating a higher number of users taking desired actions
              </p>
            </div>
          </div>
          <h2>Research</h2>
          <hr className="separator"></hr>
          <h3>Storyboard of businees clients</h3>
          <p>
            The scenario is a business meeting. They are trying to find a
            content creator to make promotion videos. However, finding the right
            one and monitoring the quality seems to be hard.
          </p>
          <img loading="lazy" src={storyboard1} alt="storyboard"></img>
          <h3>Take-aways </h3>
          <p>
            The clients users are mainly facing difficulties as following
            factors: difficulties in finding suitable creators; monitoring the
            process and ensuring the quality and lack of communication;
          </p>
          <img loading="lazy" src={storyboard2} alt="storyboard"></img>
          <h3>Existed Platforms </h3>
          <p>
            We have looked around our direct and indirect competitors, and here
            are some feedbacks we collect:
          </p>
          <img
            loading="lazy"
            src={storyboard3}
            alt="storyboard"
            style={{ marginBottom: "3rem" }}
          ></img>
        </section>
      </div>
      <section className="passioncy-background">
        <div
          className="container"
          style={{
            paddingTop: "6rem",
            paddingBottom: "6rem",
            alignItems: "start",
          }}
        >
          <h3>How might we</h3>
          <p style={{ fontSize: "2rem", fontWeight: "700", marginTop: "2rem" }}>
            Connect businesses with skilled content creators and ensure a
            transparent experience from start to finish?
          </p>
        </div>
      </section>
      <div className="passioncy template-global container">
        <section>
          <h2>Ideation</h2>
          <hr className="separator"></hr>
          <h3>Design Goals </h3>
          <img loading="lazy" src={goals} alt="goal"></img>
          <h3>User Flow</h3>
          <p>
            As we are focusing on the client side procedure, we visualized the
            User Flow.
          </p>
          <img
            loading="lazy"
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
            src={infoarch}
            alt="infoarch"
          ></img>
          <h2>Design Iteration</h2>
          <hr className="separator"></hr>
          <h3> Iteration</h3>
        </section>
      </div>
      <section style={{ width: "100vw", backgroundColor: "#F7F7F7" }}>
        <div className="container template-global passioncy">
          <div style={{ margin: "3rem 0" }}>
            <div className="before-after">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold", color: "#999999" }}>Before</p>
                <img
                  loading="lazy"
                  style={{ margin: "2rem 0" }}
                  src={comp3}
                  alt=""
                ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>After</p>
                <img
                  loading="lazy"
                  style={{ margin: "2rem 0" }}
                  src={comp4}
                  alt=""
                ></img>
              </div>
            </div>
            <p>
              New design simplifies the text for better readability, optimizing
              space allocation to reduce excessive white space, and introducing
              a more intuitive presentation for improved user understanding.
            </p>
          </div>
          <div style={{ margin: "3rem 0" }}>
            <div className="before-after">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold", color: "#999999" }}>Before</p>
                <img
                  loading="lazy"
                  style={{ margin: "2rem 0" }}
                  src={comp5}
                  alt=""
                ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>After</p>
                <img
                  loading="lazy"
                  style={{ margin: "2rem 0" }}
                  src={comp6}
                  alt=""
                ></img>
              </div>
            </div>
            <p>
              New design introduces a clear information layer to enhance content
              visibility and comprehension. User could click the card to enter
              the detailed page, but in the first sight, they could have more
              impression on each creator.
            </p>
          </div>
          <div style={{ margin: "3rem 0" }}>
            <div className="before-after">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold", color: "#999999" }}>Before</p>
                <img
                  loading="lazy"
                  style={{ margin: "2rem 0" }}
                  src={comp7}
                  alt=""
                ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>After</p>
                <img
                  loading="lazy"
                  style={{ margin: "2rem 0" }}
                  src={comp8}
                  alt=""
                ></img>
              </div>
            </div>
            <p>
              New design optimizes space utilization to minimize excessive white
              space, prominently displaying comprehensive skills and equipment
              information.
            </p>
          </div>
        </div>
        <div
          style={{ alignItems: "start" }}
          className="container template-global passioncy"
        >
          <h3>Before & After Comparison</h3>
        </div>
        <Comparison first={comp1} second={comp2} />
        <div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </section>
      <div className="container template-global passioncy">
        <section>
          <h2>Final Design</h2>
          <hr className="separator"></hr>
          <div style={{ marginTop: "3rem" }} className="final-design">
            <img loading="lazy" src={result4} alt=""></img>
            <div>
              <h3>Progress Bar</h3>
              <p>
                It shows the progress of the whole collaboration. When you're
                done with a step, it has a tick sign and you can fold it up
              </p>
              <br />
              <br />
              <h4>Pain Point</h4>
              <br />
              <p>Lacking of monitoring, visually not clear and not efficient</p>
            </div>
          </div>

          <div className="final-design">
            <div>
              <h3>Communicate with creators</h3>
              <p>Leave text comments, image, and links</p>
              <br />
              <br />
              <h4>Pain Point </h4>
              <br />
              <p>Lacking of consistent communication</p>
            </div>
            <img loading="lazy" src={result1} alt=""></img>
          </div>

          <div className="final-design">
            <img src={result2} alt=""></img>
            <div>
              <h3>Do’s and Don’ts</h3>
              <p>Emphasize on crucial points and red flags to creators</p>
              <br />
              <br />
              <h4>Pain Point</h4>
              <br />
              <p>Lacking communication and not enough personalization</p>
            </div>
          </div>

          <div className="final-design">
            <div>
              <h3>Storyboard</h3>
              <p>Leave text comments, image, and links</p>
              <br />
              <br />
              <h4>Pain Point</h4>
              <br />
              <p>Lacking of consistent communication</p>
            </div>
            <img loading="lazy" src={result3} alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Passioncy;
