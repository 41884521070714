import React, { Component } from 'react'
import './MottoStyles.css'

class Motto extends Component{
  render(){
  const { id, text } = this.props;

  return (
    <section id = {id} className="motto">
  <h2>
    {text}
  </h2>
</section>

  )
}
}

export default Motto