import { React, useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";

import "@react-pdf-viewer/core/lib/styles/index.css";
import cv from "../assets/Siqi-Chen-Designer-CV.pdf";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";

const Resume = (props) => {
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Resume", // Replace with your custom path
      title: "Resume", // Replace with your custom title
    });
  }, []);

  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;
  const handleDownload = () => {
    // Use the browser's built-in anchor element to trigger the download
    const link = document.createElement("a");
    link.href = cv;
    link.download = "cv.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
      <Navbar />

      <div
        className="container"
        style={{
          display: "flex",
          marginTop: "2rem",
          justifyContent: "center",
          width: "75%",
          height: "75%",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            style={{
              width: "50vw",
              height: "50vh",
            }}
            fileUrl={cv}
          />
        </Worker>{" "}
        <Download>
          {(props) => (
            <div
              className="link-no-underline"
              style={{
                marginTop: "2rem",
                padding: "0.5rem",
                cursor: "pointer",
              }}
              onClick={handleDownload}
            >
              Download
            </div>
          )}
        </Download>
      </div>
      <Footer />
    </div>
  );
};

export default Resume;
