import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../index.css";
import "./Pages.css";
import PageTemplatesIntro from "../components/PageTemplatesIntro";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";
import grid1 from "../assets/Roversea/grid1.png";
import grid2 from "../assets/Roversea/grid2.png";
import grid3 from "../assets/Roversea/grid3.png";
import grid4 from "../assets/Roversea/grid4.png";
import SideNavbar from "../components/SideNavbar";

const Roversea = () => {
  const templateDataRoversea = PageTemplatesIntroData[0];
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Roversea", // Replace with your custom path
      title: "Roversea", // Replace with your custom title
    });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="roversea">
        <SideNavbar />
      </div>
      <PageTemplatesIntro
        title={templateDataRoversea.title}
        headimg={templateDataRoversea.headimg}
        projectType={templateDataRoversea.projectType}
        role={templateDataRoversea.role}
        team={templateDataRoversea.team}
        contribution={templateDataRoversea.contribution}
        tools={templateDataRoversea.tools}
        intro={templateDataRoversea.intro}
        headimg2={templateDataRoversea.headimg2}
        problemStatement={templateDataRoversea.problemStatement}
        solutionImgsrc1={templateDataRoversea.solutionImgsrc1}
        solutionTitle1={templateDataRoversea.solutionTitle1}
        solution1={templateDataRoversea.solution1}
        solutionImgsrc2={templateDataRoversea.solutionImgsrc2}
        solutionTitle2={templateDataRoversea.solutionTitle2}
        solution2={templateDataRoversea.solution2}
        solutionImgsrc3={templateDataRoversea.solutionImgsrc3}
        solutionTitle3={templateDataRoversea.solutionTitle3}
        solution3={templateDataRoversea.solution3}
        solutionImgsrc4={templateDataRoversea.solutionImgsrc4}
        solutionTitle4={templateDataRoversea.solutionTitle4}
        solution4={templateDataRoversea.solution4}
      />
      <div className="roversea template-global container">
        <section>
          <h2>Research</h2>
          <hr className="separator"></hr>
          <h3>Kick-off</h3>
          <p>
            One of the biggest challenges I faced during the research process
            was understanding the diverse needs of students looking to rent
            rooms. As a student myself, I had a certain level of understanding
            of the problem, but I quickly realized that every student has unique
            needs, preferences and pain points.
            <br />
            <br />
            To gain a deeper understanding of the experiences and difficulties
            faced by students when renting rooms, I conducted a survey which was
            distributed to 23 students. The questionnaire focused on various
            aspects of the renting experience, including: <br />
            <br />
            1. The experience of renting a room
            <br />
            2. The difficulties faced
            <br />
            3. The experience of renting service
            <br />
            4. Roommates' experience
            <br />
            <br />
            The questionnaire could be found at:{" "}
            <a
              href="https://docs.google.com/document/d/1kascuw_JZBdSbGLu6erdFXKqSU4oRu-tDlywLizFScs/edit?usp=sharing"
              target="_blank"
              className="link-10"
            >
              Link
            </a>
            <br />
          </p>
        </section>
        <section>
          <h3>Gathering pieces</h3>
          <p>
            This survey helped me to gather valuable data on the needs and pain
            points of students, which informed the design.
          </p>

          <div className="roversea-grid w-container">
            <img
              src={grid1}
              loading="lazy"
              id="grid1"
              alt=""
              className="roversea-grid-img"
            />
            <img
              src={grid2}
              loading="lazy"
              id="grid2"
              alt=""
              className="roversea-grid-img"
            />
            <img
              src={grid3}
              loading="lazy"
              id="grid3"
              alt=""
              className="roversea-grid-img"
            />
            <img
              src={grid4}
              loading="lazy"
              id="grid4"
              alt=""
              className="roversea-grid-img"
            />
            <p id="text1" className="roversea-grid-text">
              1. Most people seek room resources through websites. It's{" "}
              <strong>trivial </strong>and <strong>troublesome</strong>.{" "}
            </p>
            <p id="text2" className="roversea-grid-text">
              2. <strong>Lacking of information.</strong> Many people complain
              there isn’t enough room information when they are selecting the
              room.{" "}
            </p>
            <p id="text3" className="roversea-grid-text">
              3. Many complain that it’s a <strong>tiring</strong> and
              <strong>frustrating </strong>process to communicate with
              apartments.{" "}
            </p>
            <p id="text4" className="roversea-grid-text">
              4. Roommates are like <strong>"a box of chocolates."</strong> Some
              people finds roommates through chat groups, some are assigned
              roommates by apartments.
            </p>
          </div>
        </section>
        <section>
          <div>
            <h3>Take-aways</h3>
            <p>
              Tech-savvy users, like college students like me, are having
              difficulty navigating the rental market, it is likely that those
              with less advanced technology or less favorable environmental
              conditions will have an even harder time. There is a huge
              opportunity to create a solution that addresses this need
              effectively.
              <br />
              <br />
              However, as I could not solve everything in one App, it's
              important to narrow down the scope of my project to focus on the
              most pressing needs and pain points of the target users.
              <br />
            </p>
          </div>
        </section>
        <section>
          <div>
            <h3>Dig deeper</h3>
            <p>
              To get a more detailed understanding, I conducted two interviews
              and storyboards. The interview helped me discover deeper insights
              and identify critical issues in this process.
              <br />
              <br />
              After the interviews, I developed some key themes and
              insights/quotes as supporting evidence for each key theme. I then
              identified some opportunity areas as How Might We questions to
              help guide my ideation session. <br />
            </p>
            <a
              className="lightbox1 w-inline-block w-lightbox"
              aria-label="open lightbox"
              aria-haspopup="dialog"
            >
              <img
                src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM.png"
                loading="lazy"
                sizes="(max-width: 479px) 0px, 100vw"
                srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2cd015a5994375c81_Screen%20Shot%202023-01-27%20at%206.35.28%20PM.png 2452w"
                alt=""
                className="interview"
              />
              <img
                src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM.png"
                loading="lazy"
                sizes="(max-width: 479px) 0px, 100vw"
                srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d46eb2c0c037901d74d7b6_Screen%20Shot%202023-01-27%20at%206.35.51%20PM.png 2452w"
                alt=""
                className="interview"
              />
            </a>
            <p>
              From the 6 HMW statements, I decided to focus on three:
              <br />‍<br />
              1.{" "}
              <em>
                {" "}
                How might we provide students with more comprehensive and
                accurate information about rental rooms to help them make
                informed decisions?
                <br />
                2. How might we facilitate effective communication between
                students and landlords/property managers?
                <br />
                3. How might we help students find compatible roommates?
              </em>
              <br />
            </p>
          </div>
        </section>
        <section>
          <div>
            <h3>Workshop</h3>
            <p>
              I interviewed a group of five college students and made a workshop
              with them. I let them propose their ideas of each HMW question.
              And they were asked to vote ideas using a white sticker.{" "}
            </p>
            <div className="image-top-4rem">
              <img
                src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1).jpg"
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-500.jpg 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-800.jpg 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-1080.jpg 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-1600.jpg 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-2000.jpg 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-2600.jpg 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d4985e3f4ca060515f8352_Untitled%20-%20Group%204%20(1)-p-3200.jpg 3200w"
                alt=""
                className="lightbox2-img"
              />
              <div className="note">Workshop Board</div>
            </div>

            <div className="image-top-4rem">
              <img
                src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206.jpeg"
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-500.jpeg 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-800.jpeg 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-2000.jpeg 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-2600.jpeg 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d59b9845611819706d9724_Untitled%20-%20Group%206-p-3200.jpeg 3200w"
                alt=""
                className="lightbox2-img"
              />
              <div className="note">
                Feedback on what info should be provided{" "}
              </div>
            </div>
            <div className="heading-top-4rem">
              <p>
                From the workshop, three key features are raised by
                participants:
                <br />
                1. Provide as much info and rating system
                <br />
                2. Introduce online communication means between users and
                landlords
                <br />
                3. Encourage interaction and communication between users
                <br />
              </p>
            </div>
            <h3>Where is the opportunity?</h3>
            <p>
              From the workshop, three key features are raised by participants:
              <br />
              1. Provide as much info and rating system
              <br />
              2. Introduce online communication means between users and
              landlords
              <br />
              3. Encourage interaction and communication between users
              <br />
            </p>
            <a
              className="lightbox2 w-inline-block w-lightbox"
              aria-label="open lightbox"
              aria-haspopup="dialog"
            >
              <img
                src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207.jpeg"
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-500.jpeg 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-800.jpeg 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-2000.jpeg 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-2600.jpeg 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63d5a1119eb1eefa2b687e7e_Untitled%20-%20Group%207-p-3200.jpeg 3200w"
                alt=""
                className="lightbox2-img"
              />
              <div className="note">Competitive Analysis</div>
            </a>
            <p>
              For example, my renting app could have a more user-friendly
              interface, and provides more detailed information about rental
              properties. Additionally, if my app is able to connect users with
              compatible roommates, or offer a streamlined communication system
              between renters and landlords, that could also be a unique point.
            </p>
          </div>

          <div>
            <h3>
              Feedback
              <br />
            </h3>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda0c8247b627e912f33bf_WX20230203-175901%402x.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda0c8247b627e912f33bf_WX20230203-175901%402x-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda0c8247b627e912f33bf_WX20230203-175901%402x-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda0c8247b627e912f33bf_WX20230203-175901%402x-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda0c8247b627e912f33bf_WX20230203-175901%402x-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda0c8247b627e912f33bf_WX20230203-175901%402x.png 1716w"
              alt=""
              className="image-top-4rem"
            />
          </div>
        </section>
        <section>
          <div className="heading-top-4rem">
            <h2>Ideation</h2>
            <hr className="separator"></hr>
            <h3>Persona</h3>
            <p>
              To ensure that the app meets the needs, pain points, and goals of
              the target users, a persona was created. It can help me focus on
              the features and design elements that are most important to the
              users and make sure that the app is tailored to their specific
              needs.
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20).png"
              loading="lazy"
              sizes="(max-width: 479px) 0px, 100vw"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20)-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20)-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20)-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20)-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20)-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e335eeec68c04874f1d39f_Persona%20(%20Light%20).png 2388w"
              alt=""
              className="image-top-4rem"
            />
            <div className="note">Persona Image</div>
          </div>
        </section>
        <section>
          <div>
            <h3>Concept</h3>
            <p>
              According to the three key features previously raised by
              participants
              <br />
              1. Provide as much info and rating system
              <br />
              2. Introduce online communication means between users and
              landlords
              <br />
              3. Encourage interaction and communication between users
              <br />
              <br />I have summarized three "pillars" to correspond to it：
              <br />
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e3374cb114ac3b42a5b156_63dda16c77d3e572cfd17d9e_WX20230203-180551%402x.png"
              loading="lazy"
              alt=""
              className="image-4rem"
            />
            <h3>Information architecture</h3>
            <p>
              After I had a rough sitemap for our concept, I started to create
              wireframe iterations for how this app would manifest itself. I
              designed two main user flows. For the first one, user could
              individually sign a room. For the second one, user have the chance
              to find another ones to be roommates. And the two flows could be
              connected by two pages.
              <br />
              <br />I did not add a "match" feature on the roommate part, as I
              think the app cannot do all the work. Users would like to use
              other ways (WhatsApp, Messenger, etc.) to make the communication
              finally. However, finding each other matters, which is what the
              app's doing.
              <br />
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda56b77d3e5c700d1c4ca_WX20230203-181908%402x.png"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda56b77d3e5c700d1c4ca_WX20230203-181908%402x-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda56b77d3e5c700d1c4ca_WX20230203-181908%402x-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda56b77d3e5c700d1c4ca_WX20230203-181908%402x-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda56b77d3e5c700d1c4ca_WX20230203-181908%402x-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda56b77d3e5c700d1c4ca_WX20230203-181908%402x.png 1906w"
              alt=""
              className="roversea-userflow-2"
            />
            <div className="note">2 User flows</div>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch.png"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b0673572929834_arch-p-2600.png 2600w"
              alt=""
              className="image-top-4rem"
            />
            <div className="note">Information architecture</div>
          </div>
          <img
            src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314.png"
            sizes="100vw"
            srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6352897185b067faaf929835_1314-p-3200.png 3200w"
            alt=""
            className="image-2rem"
          />
          <div>
            {" "}
            <h2>Design Iteration</h2>
            <hr className="separator"></hr>
            <h3>Low-fidelty prototype</h3>
            <p>
              At the low-fidelity &nbsp;stage, the primary focus was on feature
              navigation, information architecture, and mapping the concept at a
              high level.
              <br />
            </p>
            <div className="image-top-4rem">
              <a
                href="#"
                className="lightbox3 w-inline-block w-lightbox"
                aria-label="open lightbox"
                aria-haspopup="dialog"
              >
                <img
                  src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dae78e669a11521ac1b91a_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230202062710.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 276.6328125px, 357.1953125px"
                  srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dae78e669a11521ac1b91a_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230202062710-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dae78e669a11521ac1b91a_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230202062710-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dae78e669a11521ac1b91a_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230202062710.png 900w"
                  alt=""
                  className="lighbox3-img"
                />
                <img
                  src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17.jpeg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 276.6328125px, 357.1953125px"
                  srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-500.jpeg 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-800.jpeg 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-2000.jpeg 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-2600.jpeg 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dd9b221757c8797ce19611_WechatIMG17-p-3200.jpeg 3200w"
                  alt=""
                  className="lighbox3-img2"
                />
                <img
                  src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d2abd8c53c2d139d46d2_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230210131400.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 19vw, (max-width: 991px) 138.3125px, 178.59375px"
                  srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d2abd8c53c2d139d46d2_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230210131400-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d2abd8c53c2d139d46d2_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230210131400.png 611w"
                  alt=""
                  className="lighbox3-img3"
                />
              </a>
              <div className="note">Drafts</div>
            </div>
          </div>
        </section>
        <div>
          <div>
            <h3>Feedback</h3>
            <p>
              I have gathered feedback from users on my lo-fi prototype. The
              feedback has been invaluable in helping me to identify areas for
              improvement in terms of user experience. Some of the key takeaways
              from the feedback include the need for a more intuitive
              navigation, a more comprehensive and detailed property information
              page, and a more streamlined communication system between renters
              and landlords.
              <br />
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda2474bab587539c0f2b3_WX20230203-180912%402x.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda2474bab587539c0f2b3_WX20230203-180912%402x-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda2474bab587539c0f2b3_WX20230203-180912%402x-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda2474bab587539c0f2b3_WX20230203-180912%402x-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda2474bab587539c0f2b3_WX20230203-180912%402x-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63dda2474bab587539c0f2b3_WX20230203-180912%402x.png 1718w"
              alt=""
              className="image-top-4rem"
            />
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5d1151ce114e88a91f6e6_white-mockup%20%E2%80%93%2022.png 2880w"
              alt=""
              className="image-top-4rem"
            />
            <div className="note">Part of iteration</div>
          </div>
        </div>
        <div>
          <div>
            <h3>Theme</h3>
            <p>
              When I was designing the theme, I not only tried to fit the theme
              into the general idea, but also referred to some psychology
              contents and ios design standards.
              <br />
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63db2ac28c99fe3d010661e8_Theme-p-3200.png 3200w"
              alt=""
              className="image-top-4rem"
            />{" "}
            <h2>Final Design</h2>
            <hr className="separator"></hr>
            <h3>High-fidelty prototype</h3>
          </div>
          <img
            src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018.png"
            loading="lazy"
            sizes="100vw"
            srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a574b9c20d0e3e83fc_white-mockup%20%E2%80%93%2018-p-3200.png 3200w"
            alt=""
            className="image-top-4rem"
          />
          <img
            src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019.png"
            loading="lazy"
            sizes="100vw"
            srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019-p-3200.png 3200w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a2d86a5a146154e81b_white-mockup%20%E2%80%93%2019.png 5760w"
            alt=""
            className="image-top-4rem"
          />
          <img
            src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020.png"
            loading="lazy"
            sizes="100vw"
            srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c7a474b9c2b6fb3e83e3_white-mockup%20%E2%80%93%2020-p-3200.png 3200w"
            alt=""
            className="image-top-4rem"
          />
          <img
            src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021.png"
            loading="lazy"
            sizes="100vw"
            srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63e5c83e4107803233d57d22_white-mockup%20%E2%80%93%2021-p-3200.png 3200w"
            alt=""
            className="image-top-4rem"
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Roversea;
