import container from "../assets/HomePage/container.webp";
import passioncy from "../assets/HomePage/passioncy.webp";
import Mockup1 from "../assets/HomePage/Mockup1.webp";
import textale from "../assets/HomePage/textale.webp";
import cover from "../assets/Orelax/cover2.png";
import cover2 from "../assets/1214/cover2.webp";
import vr1 from "../assets/oculus.png";
import onedeal from "../assets/onedeal.webp";

const LongCardData = [
  {
    imgsrc: container,
    heading: "SaaS Platform",
    title: "DrayEasy",
    skills: "UX/UI Design",
    link: "/Drayeasy",
  },
  {
    imgsrc: passioncy,
    heading: "SaaS Platform",
    title: "Passioncy",
    skills: "UX/UI Design | React.js",
    link: "/Passioncy",
  },
  {
    imgsrc: onedeal,
    heading: "SaaS Platform",
    title: "OneDeal",
    skills: "Front-End Development | UX/UI Design",
    link: "/OneDeal",
  },
  {
    imgsrc: textale,
    heading: "Clothing Brand",
    title: "Textale User Research",
    skills: "Marketing | User Research | Persona Buidling",
    link: "/Textale",
  },
  {
    imgsrc: Mockup1,
    heading: "Mobile App",
    title: "Roversea",
    skills: "UX/UI Design",
    link: "/Roversea",
  },
  {
    imgsrc: cover,
    heading: "Mobile App",
    title: "O Relax",
    skills: "App Design",
    link: "/Orelax",
  },
  {
    imgsrc: cover2,
    heading: "Web Design",
    title: "1214 Music Club",
    skills: "UX/UI Design",
    link: "/1214",
  },
  {
    imgsrc: vr1,
    heading: "VR Game",
    title: "VR Escape Room",
    skills: "Game Design | VR Development",
    link: "https://siqi.webflow.io/vr-escape-room",
  },
];

export default LongCardData;
