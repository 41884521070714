import React from "react";
import Navbar from "../components/Navbar";
import Comparison from "../components/Comparison";
import Motto from "../components/Motto";
import LongCard from "../components/LongCard";
import LongCardData from "../components/LongCardData";
import SquareCard from "../components/SquareCard";
import SquareCardData from "../components/SquareCardData";
import Footer from "../components/Footer";
import coder from "../assets/HomePage/coder.webp";
import designer from "../assets/HomePage/designer.webp";

import "../index.css";

const Home = () => {
  const LongCardData1 = LongCardData.slice(0, 5);
  const LongCardData2 = LongCardData.slice(-3);

  return (
    <div>
      <Navbar />
      <div className="mobile">
        <Comparison first={coder} second={designer} />
      </div>

      <div className="container">
        <Motto text="I'm Siqi. I empower the pleasant user experiences with a code-driven mindset." />

        {LongCardData1.map((val, ind) => {
          return (
            <LongCard
              key={ind}
              imgsrc={val.imgsrc}
              heading={val.heading}
              title={val.title}
              skills={val.skills}
              link={val.link}
            />
          );
        })}
        <Motto
          id="other-work"
          text="Here are some other works I have done. Hope you like them."
        />

        <div className="mobile-hide">
          {SquareCardData.map((v, i) => {
            return (
              <SquareCard
                key={i}
                imgsrc={v.imgsrc}
                title={v.title}
                type={v.type}
                description={v.description}
                link={v.link}
              />
            );
          })}
        </div>

        <section className="mobile-show">
          {LongCardData2.map((val, ind) => {
            return (
              <LongCard
                key={ind}
                imgsrc={val.imgsrc}
                heading={val.heading}
                title={val.title}
                skills={val.skills}
                link={val.link}
              />
            );
          })}
        </section>
        <a href="/Portfolio" className="see-all">
          <span className="link-no-underline">See All</span>
        </a>
        <div id="contact" className="container motto">
          <h2>Get in touch with me:)</h2>
          <div className="contact-icon">
            <a
              className="link-no-underline"
              href="https://www.linkedin.com/in/51q1ch3n/"
            >
              LinkedIn
            </a>
            <a
              id="mail"
              className="link-no-underline"
              href="mailto:siqichen@utexas.edu"
            >
              Mail
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
