import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SideNavbar from "../components/SideNavbar";
import "../index.css";
import "./Pages.css";

import PageTemplatesIntro from "../components/PageTemplatesIntro";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";
import stakeholder from "../assets/Drayeasy/stakeholder.webp";
import context from "../assets/Drayeasy/context.webp";
import solution from "../assets/Drayeasy/solution.webp";
import challenges from "../assets/Drayeasy/challenge.webp";
import interview from "../assets/Drayeasy/interview.webp";
import persona from "../assets/Drayeasy/persona.webp";
import journeymap from "../assets/Drayeasy/journeymap.webp";
import infoarch from "../assets/Drayeasy/infoarch.webp";
import wireframe1 from "../assets/Drayeasy/wireframe1.webp";
import wireframe2 from "../assets/Drayeasy/wireframe2.webp";
import v1 from "../assets/Drayeasy/comp1.webp";
import v2 from "../assets/Drayeasy/comp2.webp";
import v3 from "../assets/Drayeasy/comp3.webp";
import v4 from "../assets/Drayeasy/comp4.webp";

import desginprin from "../assets/Drayeasy/desginprin.webp";
import mapprinciples from "../assets/Drayeasy/map_principles.webp";
import design from "../assets/Drayeasy/design.webp";
import map1 from "../assets/Drayeasy/map1.webp";
import map2 from "../assets/Drayeasy/map2.webp";
import map3 from "../assets/Drayeasy/map3.webp";
import map4 from "../assets/Drayeasy/map4.webp";
import map5 from "../assets/Drayeasy/map5.webp";
import dashboard from "../assets/Drayeasy/dashboard.webp";
import gif from "../assets/Drayeasy/design.gif";

const Drayeasy = () => {
  const templateDatadrayeasy =
    PageTemplatesIntroData[PageTemplatesIntroData.length - 1];
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Drayeasy", // Replace with your custom path
      title: "Drayeasy", // Replace with your custom title
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="drayeasy">
        <SideNavbar />
        <PageTemplatesIntro
          title={templateDatadrayeasy.title}
          headimg={templateDatadrayeasy.headimg}
          projectType={templateDatadrayeasy.projectType}
          role={templateDatadrayeasy.role}
          team={templateDatadrayeasy.team}
          contribution={templateDatadrayeasy.contribution}
          tools={templateDatadrayeasy.tools}
          intro={templateDatadrayeasy.intro}
        />
        <div className="drayeasy template-global container">
          <section>
            <h2>Overview</h2>
            <hr className="separator"></hr>
            <h3>Context</h3>
            <p>
              DrayEasy is an online platform that specializes in providing
              freight forwarding and logistics services. It offers a digital
              solution for businesses and individuals to manage and streamline
              their shipping and transportation processes. DrayEasy mainly focus
              on the "last mile" of the logistics process, which refers to the
              journey after commodity was shipped to the port.
            </p>
            <img loading="lazy" src={context} alt="stakeholder"></img>
          </section>
          <section>
            <h3>Stakeholders</h3>
            <img loading="lazy" src={stakeholder} alt="stakeholder"></img>
            <h3>Challenges</h3>
            <p>
              The current DrayEasy dashboard is a minimum viable product which
              only contains basic functions. The company uses e-mail as the
              service method as they expect to use a developed platform. Our
              challenge is to ease the user's understanding and make it easier
              for them to use. This effectively translates into meaningful
              business outcomes like increasing conversion rates.{" "}
            </p>
            <img
              loading="lazy"
              style={{ margin: "2rem 0" }}
              src={challenges}
              alt="stakeholder"
            ></img>
            <h3>Solution</h3>
            <p style={{ marginBottom: "2rem" }}>
              A new dashboard is designed. I took part in the layout iteration
              and focused on the map design.
            </p>
          </section>
        </div>
        <img loading="lazy" src={solution} alt="solution"></img>

        <div className="drayeasy template-global container">
          <section>
            <h3> Impact </h3>
            <p>
              The client has confirmed the design will be released on the new
              platform version.
            </p>
            <h2>Research</h2>
            <hr className="separator"></hr>
            <h3>User Interview</h3>
            <p>
              From the interview, it's clear that our clients are concerned
              about enhancing order tracking and management. The interviewees
              desire a more informative and user-friendly website that displays
              updates, suggests adding vital data to the order table, values
              clear status updates and key dates, and appreciates a saving
              documents function.
            </p>
            <img loading="lazy" src={interview} alt="stakeholder"></img>
            <h3>Persona</h3>
            <img loading="lazy" src={persona} alt="stakeholder"></img>
            <img
              style={{ marginTop: "2rem" }}
              loading="lazy"
              src={journeymap}
              alt="journeymap"
            ></img>
          </section>
        </div>
        <section className="passioncy-background">
          <div
            className="container"
            style={{
              marginTop: "4rem",
              marginBottom: "4rem",

              paddingTop: "6rem",
              paddingBottom: "6rem",
              alignItems: "start",
            }}
          >
            <h3>How might we</h3>
            <p
              style={{
                fontSize: "2rem",
                fontWeight: "700",
                marginTop: "2rem",
              }}
            >
              Optimize the DrayEasy dashboard to enhance user experience and
              reduce operational costs?
            </p>
          </div>
        </section>
        <div className="drayeasy template-global container">
          <section>
            <h2>Ideation</h2>
            <hr className="separator"></hr>
            <h3>User Flow</h3>
            <img loading="lazy" src={infoarch} alt="infoarch"></img>
            <h2>Design Iteration</h2>
            <hr className="separator"></hr>
            <h3>Design Principles </h3>
            <img loading="lazy" src={desginprin} alt="desginprin"></img>
            <h3>Layout Exploration</h3>
            <p>
              Exploring the dashboard layout based on information structure 
            </p>
            <img loading="lazy" src={wireframe1} alt="desginprin"></img>
            <img loading="lazy" src={wireframe2} alt="desginprin"></img>
            <p style={{ fontWeight: "bold", marginBottom: "2rem" }}>Feedback</p>
            <p>
              Full width map is better to show more information and interaction.
            </p>
            <br />
            <p>
              Industry news doesn’t need to hold such big space. it is not
              urgent.
            </p>
            <h3>Layout Iteration</h3>
            <div style={{ margin: "3rem 0" }}>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="before-after"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>v1</p>
                    <img
                      loading="lazy"
                      style={{ margin: "2rem 0" }}
                      src={v1}
                      alt=""
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>v2</p>
                    <img
                      loading="lazy"
                      style={{ margin: "2rem 0" }}
                      src={v2}
                      alt=""
                    ></img>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>v3</p>
                    <img
                      loading="lazy"
                      style={{ margin: "2rem 0" }}
                      src={v3}
                      alt=""
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>v4</p>
                    <img
                      loading="lazy"
                      style={{ margin: "2rem 0" }}
                      src={v4}
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>
              <p>
                The general iteration idea is to leave more space and attention
                for map. As a result, the map is on the top of table, the data
                cards are removed and the industry news will show while the
                mouse is hovering.
              </p>
            </div>
            {/*Map Principles */}
            <h3>Map Principles</h3>
            <p>
              Map information is complex, and the production of good maps
              requires collaboration among multiple parties at three levels:
              data support, functional design, and sensory communication.{" "}
            </p>{" "}
            <img loading="lazy" src={mapprinciples} alt="stakeholder"></img>
            <h3>Design System</h3>
            <p>
              <b>Main Color:</b> Based on cold colors, and cold colors with
              differences in hue for displaying different categories;
            </p>
            <br />
            <p>
              <b>Error color: </b>Used to flag anomalies.
            </p>
            <br />
            <p>
              <b>Position points: </b>Used to convey location information,
              maximizes the informationand reduces content occlusion;
            </p>
            <br />
            <p>
              <b>Trajectory points: </b>It is the point where the location
              information is selected and the track is recorded
            </p>
            <br />
            <img loading="lazy" src={design} alt="stakeholder"></img>
          </section>
        </div>

        <div className="drayeasy template-global container">
          <section>
            <h2>Final Design</h2>
            <hr className="separator"></hr>
            <h3>Map Design</h3>
            <p>
              1.There are quantifiable indicators on the system map that need to
              be distinguished by color contrast. The darker the color scale,
              the larger the icon/number of icons.
            </p>
            <img
              className="drayeasy-map"
              loading="lazy"
              src={map1}
              alt="map1"
            ></img>
            <p>2.See the port/warehouse containers' at.</p>
            <img
              className="drayeasy-map"
              loading="lazy"
              src={map2}
              alt="map2"
            ></img>
            <p>
              3.Choose a container to deliever, and starting point and
              destination are showed on map.
            </p>
            <img
              className="drayeasy-map"
              loading="lazy"
              src={map3}
              alt="map3"
            ></img>{" "}
            <p>4.A truck shows the progress of the route and time remaining.</p>
            <img
              className="drayeasy-map"
              loading="lazy"
              src={map4}
              alt="map4"
            ></img>{" "}
            <p>5. Delievery finnished.</p>
            <img
              className="drayeasy-map"
              loading="lazy"
              src={map5}
              alt="map5"
            ></img>
            <h3>Dashboard Design</h3>
            <img loading="lazy" src={dashboard} alt="stakeholder"></img>
            <img
              style={{ width: "100%" }}
              loading="lazy"
              src={gif}
              alt="stakeholder"
            ></img>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Drayeasy;
