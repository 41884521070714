import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../index.css";
import "./Pages.css";

import PageTemplatesIntro from "../components/PageTemplatesIntro";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";

const Orelax = () => {
  const templateDataOrelax = PageTemplatesIntroData[1];
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Orelax", // Replace with your custom path
      title: "Orelax", // Replace with your custom title
    });
  }, []);
  return (
    <div>
      <Navbar />
      <PageTemplatesIntro
        title={templateDataOrelax.title}
        headimg={templateDataOrelax.headimg}
        projectType={templateDataOrelax.projectType}
        role={templateDataOrelax.role}
        team={templateDataOrelax.team}
        contribution={templateDataOrelax.contribution}
        tools={templateDataOrelax.tools}
        intro={templateDataOrelax.intro}
        headimg2={templateDataOrelax.headimg2}
        problemStatement={templateDataOrelax.problemStatement}
        solutionImgsrc1={templateDataOrelax.solutionImgsrc1}
        solutionTitle1={templateDataOrelax.solutionTitle1}
        solution1={templateDataOrelax.solution1}
        solutionImgsrc2={templateDataOrelax.solutionImgsrc2}
        solutionTitle2={templateDataOrelax.solutionTitle2}
        solution2={templateDataOrelax.solution2}
        solutionImgsrc3={templateDataOrelax.solutionImgsrc3}
        solutionTitle3={templateDataOrelax.solutionTitle3}
        solution3={templateDataOrelax.solution3}
      />
      <div style={{ marginTop: "8rem" }}></div>

      <div className="orelax">
        <section className="section_dark orelax ">
          <div className=" container-22 w-container">
            <h2>
              Take-aways
              <br />
              ‍‍
            </h2>
            <p>
              After I began to dive deeper into the technical requirements and
              business considerations, I quickly realized that there were
              significant challenges to overcome.
              <br />
              <br />
              On the technical side, I recognized that the inclusion of a diary
              function would require additional server storage, which could be
              costly and raise concerns about data privacy.
              <br />
              <br />
              In addition, the visual aspects of the "Tree Hole" feature would
              require additional engineering resources to develop and implement.
              As OPPO Relax is not a non-profit app, the company won't put much
              resources into it. I needed to balance that with the technical
              constraints and costs associated with developing and implementing
              these visual effects.
              <br />
            </p>
          </div>
        </section>
        <section className="section_light ">
          <div className="w-container">
            <h2>
              Context
              <br />
              ‍‍
            </h2>
            <p>
              OPPO Relax is a mobile app that aims to help users relax and
              reduce stress through the use of ambient sounds and soothing white
              noise. The app includes a variety of features, including the
              ability to play white noise, such as rain or waves, and the option
              to listen to the sounds of various cities around the world, from
              Tokyo to Reykjavik.
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023.png"
              loading="lazy"
              sizes="(max-width: 479px) 0px, 100vw"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636373d32d93214388639340_%E8%AF%A6%E6%83%85%E5%9B%BE%2023.png 2412w"
              alt=""
            />
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024.png"
              loading="lazy"
              sizes="(max-width: 479px) 0px, 100vw"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63636fd12a80d51b7952b05e_%E8%AF%A6%E6%83%85%E5%9B%BE%2024.png 2412w"
              alt=""
            />
            <h2 className="heading-top-4rem">
              User research #1: users relax by talking
              <br />‍
            </h2>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f143327d7541e924a972ab_key-conclusion.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f143327d7541e924a972ab_key-conclusion-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f143327d7541e924a972ab_key-conclusion-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f143327d7541e924a972ab_key-conclusion-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f143327d7541e924a972ab_key-conclusion.png 1582w"
              alt=""
              className="image-2rem"
            />
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6364449bacc179b35b0a821c_Frame%203557.png"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6364449bacc179b35b0a821c_Frame%203557-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6364449bacc179b35b0a821c_Frame%203557-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6364449bacc179b35b0a821c_Frame%203557-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6364449bacc179b35b0a821c_Frame%203557.png 1582w"
              alt=""
              className="image-2rem"
            />
            <p>
              It was found that 81% of users prefer to relax by talking.
              Interestingly, the data also revealed that people under 18 years
              old and over 45 years old were particularly fond of talking to
              relax. On the other hand, users who do not prefer talking mainly
              engage in other activities, such as playing games, sports, and
              meditation to unwind.
            </p>
            <h2 className="heading-top-4rem">
              User research #2: users use the app to relax
              <br />‍
            </h2>
            <p>
              I looked at the analysis of the app usage data for a given month
              to get some insight.{" "}
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f147f0c16726f96f4d978e_Frame%203560.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f147f0c16726f96f4d978e_Frame%203560-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f147f0c16726f96f4d978e_Frame%203560-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f147f0c16726f96f4d978e_Frame%203560-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f147f0c16726f96f4d978e_Frame%203560.png 1582w"
              alt=""
              className="image-2rem"
            />
            <p>
              It appears that the app is most frequently used during the evening
              hours, with peak usage occurring between 18:00 and 22:00.
              Additionally, the data suggests that usage is also relatively high
              during the afternoon, between 13:00 and 18:00.
              <br />
              <br />
              This data may suggest that users are primarily using the app to
              unwind and relax after a long day, or to take a break during their
              workday.{" "}
            </p>
          </div>
        </section>
        <section className="section_dark orelax ">
          <div className=" w-container">
            <h2>
              How to talk to myself?
              <br />
              <br />
            </h2>
            <p>
              First, the fact that users have a high demand for talking suggests
              that a feature that supports or encourages talking could be highly
              engaging and well-received. Second, the usage time data suggests
              that users are primarily using the app as a way to unwind and
              relax after a long day, or to help them sleep. <br />
              <br />
              OPPO&nbsp;Relax doesn't include a community feature. I also do not
              want to introduce a social function. It's crucial to figure out
              how a user would like to talk to himself/herself. A voice
              recording or messaging functionality, or a feature that provides
              audiovisual feedback as users speak could be a possible solution.
              <br />
            </p>
          </div>
        </section>
        <section className="section_light ">
          <div className="w-container">
            <h2>Refine the problem statement‍</h2>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f14f6d700f68adef3500be_Untitled%20-%20Group%208.jpg"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f14f6d700f68adef3500be_Untitled%20-%20Group%208-p-500.jpg 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f14f6d700f68adef3500be_Untitled%20-%20Group%208-p-800.jpg 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f14f6d700f68adef3500be_Untitled%20-%20Group%208-p-1080.jpg 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f14f6d700f68adef3500be_Untitled%20-%20Group%208-p-1600.jpg 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f14f6d700f68adef3500be_Untitled%20-%20Group%208.jpg 1681w"
              alt=""
              className="image-2rem"
            />
            <p className="note">Refine the HMWs</p>
            <p>
              <br />
              After reviewed the user research, I proposed 5 new HMWs and refine
              the problem statement into: "How might we provide a private and
              way for users to communicate and express their thoughts and
              feelings, while also providing a personalized soothing and
              relaxing feedback to promote stress reduction and relaxation?"
            </p>
          </div>
        </section>
        <section className="section_dark orelax ">
          <div className=" w-container">
            <h2>
              Competitive analysis
              <br />
              ‍‍‍‍‍
            </h2>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f152fda2038aa76069eafe_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219063123.png"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f152fda2038aa76069eafe_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219063123-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f152fda2038aa76069eafe_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219063123-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f152fda2038aa76069eafe_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219063123-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f152fda2038aa76069eafe_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219063123-p-1600.png 1600w"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              alt=""
              className="image-2rem"
            />
            <p>
              First, the fact that users have a high demand for talking suggests
              that a feature that supports or encourages talking could be highly
              engaging and well-received. Second, the usage time data suggests
              that users are primarily using the app as a way to unwind and
              relax after a long day, or to help them sleep. <br />
              <br />
              OPPO&nbsp;Relax doesn't include a community feature. I also do not
              want to introduce a social function. It's crucial to figure out
              how a user would like to talk to himself/herself. A voice
              recording or messaging functionality, or a feature that provides
              audiovisual feedback as users speak could be a possible solution.
              <br />
            </p>
          </div>
        </section>
        <section className="section_light ">
          <div className="w-container">
            <h2>Persona‍‍‍‍</h2>
            <br />
            <br />

            <p>
              To ensure that the app meets the needs, pain points, and goals of
              the target users, a persona was created. It can help me focus on
              the features and design elements that are most important to the
              users and make sure that the app is tailored to their specific
              needs.
              <br />
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled.png"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-2600.png 2600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/636440c905e80478502e65da_Untitled-p-3200.png 3200w"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              alt=""
              className="image-2rem"
            />
          </div>
        </section>
        <section className="section_dark orelax ">
          <div className=" w-container">
            <h2>
              Ideation
              <br />
              ‍‍‍‍‍
            </h2>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f1649d7d7541b8efabd520_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219073845.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f1649d7d7541b8efabd520_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219073845-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f1649d7d7541b8efabd520_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219073845-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f1649d7d7541b8efabd520_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219073845.png 960w"
              alt=""
              className="image-4rem"
            />
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f164fd69cfc05c743d86fc_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219075259.png"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f164fd69cfc05c743d86fc_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219075259-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f164fd69cfc05c743d86fc_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219075259-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f164fd69cfc05c743d86fc_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219075259-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f164fd69cfc05c743d86fc_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219075259-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f164fd69cfc05c743d86fc_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230219075259.png 1751w"
              alt=""
              className="image-4rem"
            />
            <p id="w-node-_8ff9f64d-69cd-66df-9737-03f46facb5c9-51cff0a5">
              I decided to make a "Tree Hole" function, which means the user
              could talk to the phone freely and nobody will know. The user
              could simultaneously get visualization feedback from the sound.
              The user could check their audio diaries on a list.
              <br />
              <br />
              Some visualization options I have found are shown in the figures.{" "}
              <br />
            </p>
          </div>
        </section>
        <section className="section_light ">
          <div className="w-container">
            <h2>Information architecture</h2>
            <p>
              The information architecture for the "Tree Hole" feature consists
              of three main sections or options: "Press to start", "Dates", and
              "Settings". The information architecture for the "Tree Hole"
              feature is designed to be intuitive and easy to navigate, allowing
              users to access and engage with the feature in a way that is
              comfortable and stress-free.
              <br />
            </p>
            <img
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2.png"
              loading="lazy"
              sizes="(max-width: 479px) 0px, 100vw"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63652cdf0f3dd8797e7fd4c5_Untitled2.png 2496w"
              alt=""
            />
            <h2 id="w-node-abb6c5cc-1791-068b-0ed7-55b9f3dda62a-51cff0a5">
              Low-fidelty prototype
              <br />
              ‍‍‍‍‍‍
            </h2>
            <img
              className="image-4rem"
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365307e0f3dd8725e800ce9_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221104233153.jpg"
              loading="lazy"
              sizes="(max-width: 479px) 0px, 100vw"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365307e0f3dd8725e800ce9_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221104233153-p-500.jpg 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365307e0f3dd8725e800ce9_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221104233153-p-800.jpg 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365307e0f3dd8725e800ce9_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221104233153-p-1080.jpg 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365307e0f3dd8725e800ce9_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221104233153-p-1600.jpg 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365307e0f3dd8725e800ce9_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221104233153.jpg 1702w"
              alt=""
            />
            <img
              className="image-4rem"
              src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f16c3ca2038a7b5b6bf5f8_lofi.png"
              loading="lazy"
              sizes="(max-width: 479px) 0px, 100vw"
              srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f16c3ca2038a7b5b6bf5f8_lofi-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f16c3ca2038a7b5b6bf5f8_lofi-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f16c3ca2038a7b5b6bf5f8_lofi-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/63f16c3ca2038a7b5b6bf5f8_lofi.png 1103w"
              alt=""
            />

            <h2 className="heading-top-4rem">High-fidelty prototype</h2>
          </div>
          <img
            src="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202.png"
            loading="lazy"
            sizes="(max-width: 479px) 0px, 100vw"
            srcSet="https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202-p-500.png 500w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202-p-800.png 800w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202-p-1080.png 1080w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202-p-1600.png 1600w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202-p-2000.png 2000w, https://uploads-ssl.webflow.com/6352897185b067ac5992966b/6365f02976e2056932f6be33_MacBook%20Air%20-%202.png 2321w"
            alt=""
          />
        </section>
        <section className="section_dark orelax ">
          <div className=" w-container">
            <h2>
              Reflection
              <br />‍
            </h2>
            <p id="w-node-_60881fe2-e96f-b246-dcd5-76b9479e79f9-51cff0a5">
              - Business project is different from school project
              <br />
              It is common for industry projects to have different goals and
              constraints compared to school projects. In the industry, the
              focus is often on creating products or features that meet the
              needs of users and provide value to the business. This can involve
              considerations such as user research, market analysis, and
              technical feasibility, as well as considerations related to cost,
              resources, and time-to-market.
              <br />‍<br />
              -Failure is a natural part of the design process
              <br />
              It is important to learn from these experiences and use them as
              opportunities for growth and improvement. While it may be
              disappointing that the "Tree Hole" feature was not ultimately
              implemented, it is important to recognize the value of the
              experience and the lessons learned.
              <br />
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Orelax;
