//roversea
import r1 from "../assets/Roversea/r1.png";
import gif1 from "../assets/Roversea/gif1.gif";
import gif2 from "../assets/Roversea/gif2.gif";
import gif3 from "../assets/Roversea/gif3.gif";
import gif4 from "../assets/Roversea/gif4.gif";

//orelax
import treehole from "../assets/Orelax/treehole.webp";
import treehole2 from "../assets/Orelax/treehole2.png";
import treehole3 from "../assets/Orelax/img1.png";
import talking from "../assets/Orelax/img2.png";
import diary from "../assets/Orelax/img3.webp";

//passioncy
import passioncy from "../assets/passioncy/headimg.webp";

//textale
import textale from "../assets/textale/headimg.webp";
import textale2 from "../assets/textale/3.png";

//onedeal
import onedeal from "../assets/onedeal.png";

//drayeasy
import headimg from "../assets/Drayeasy/headimg.webp";

const PageTemplatesIntroData = [
  //Roversea
  {
    headimg: r1,
    title: "Roversea",
    projectType: "Individual Project",
    role: "UX Designer",
    team: "Myself",
    contribution: "User Research\n Prototyping \n Visual Design\nHi-fi Design",
    tools: "Figma \n Adobe XD",
    intro:
      "Give students confidence to rent a room by providing detailed information and methods of finding a roommate.",
    //headimg2: r2,
    problemStatement:
      "I suffered from renting a room when I am moving to Austin, especially it is my first time to the United States. Obviously, I was not the only one who thought it was such a frustrating process. I have heard a few friends complaining the terrible renting experience to me. How might we let students rent a room with confidence?",
    solutionImgsrc1: gif1,
    solutionTitle1: "Search in thumbnail mode, detail mode and map mode",
    solution1:
      "- Three kinds of showing methods could provide as much information when search",

    solutionImgsrc2: gif2,
    solutionTitle2: "Detailed page covers necessary info",
    solution2: "- Include as many crucial features in deciding a renting room",

    solutionImgsrc3: gif3,
    solutionTitle3: "Search similar people like you",
    solution3: "- Potential properties and roommates based on preferences.",

    solutionImgsrc4: gif4,
    solutionTitle4: "Enter roommate pool from detailed page",
    solution4: "- Choose roommates who interested at the same property.",
  },

  //O Relax
  {
    headimg: treehole,
    title: "O Relax ",
    projectType: "Intern Project",
    role: "UX Designer",
    contribution: "User Research\n Prototyping \n Visual Design\nHi-fi Design",
    tools: "Figma \n Adobe XD",
    team: "Myself",
    intro: "A feature aims to relax and distress people",
    headimg2: treehole2,
    problemStatement:
      "While Covid-19 is sweeping the world physically and mentally, the pressure of life is increasing. More and more users have anxiety, insomnia and difficulty in concentration, and stress reduction has become a potential demand of users. I was asked to bring a new proposal, a new feature to the OPPO Relax app. \n How might we help them to explore a more relaxing experience and a way to destress?",
    solutionImgsrc1: treehole3,
    solutionTitle1: "Speak out to tree hole",
    solution1:
      "- An anonymous place where you can openly share their thoughts, emotions, and secrets without revealing their identities.",

    solutionImgsrc2: talking,
    solutionTitle2: "Talking to the moon",
    solution2:
      "- When you are speaking, the tree hole will give you an audiovisual feedback, it will map out the corresponding music and vision according to the sound and content you talk, and feedback to you.",

    solutionImgsrc3: diary,
    solutionTitle3: "Diary",
    solution3:
      "- The content of these feedbacks will be deposited in the form of a diary. It makes you feel that every word of your heart is heard and recognized.",
  },

  //Passioncy
  {
    headimg: passioncy,
    title: "Passioncy",
    projectType: "Intern Project",
    role: "UX Engineer",
    team: "3 Engineers, \n 1 PM \n 3 Designers",
    contribution:
      "User Research\n Prototyping \n Visual Design\nWeb App Development",
    tools: "Figma \n Javascript \n React",
    intro: "Bridge together clients and creators for short video strategies.",
  },

  //Textale
  {
    headimg: textale,
    style: "backgroundColor:#e7e2dc",
    title: "Textale User Research",
    projectType: "Part-time Project",
    role: "UXR Co-Lead",
    team: "1 UXR Lead, \n 1 UXR Co-Lead \n 5 UX Researchers",
    contribution: "User Research\n Data Visualization ",
    intro: "Know the customers better",
    tools: "Figma \n Attribuly \n Shopify",
  },

  //1214
  {
    style: "backgroundColor:#e7e2dc",
    title: "1214 Web Design",
    projectType: "Part-time Project",
    role: "Lead of Development Team",
    period: "1 months",
    intro: "The official website of a music club",
    headimg2: textale2,
    problemStatement:
      "1214 Entertainment, a music club, needs a website to establish a digital presence, attract new customers, showcase its music events and performances, and provide information about the club's offerings, schedule, and pricing. The website should be visually appealing, easy to navigate, mobile-responsive, and feature-rich to enhance the user experience and promote customer engagement.",
  },

  //OneDeal
  {
    headimg: onedeal,
    title: "OneDeal Website",
    projectType: "Intern at Onedeal",
    role: "Front-end Developer & Web Designer",
    team: "1 Designer, \n 1 PM \n 2 Engineers",
    contribution: "UX Design\n Front-end Development \n Visual Design\n",
    tools: "React.js \n Figma \n AWS",
    intro: "Web Design & Development",
  },

  //Drayeasy
  {
    headimg: headimg,
    title: "DrayEasy Dashboard",
    projectType: "Part-time at DrayEasy",
    role: "UX Designer",
    team: "5 Designers, \n 1 PM \n 3 Designers",
    contribution: "User Research\n Prototyping \n Visual Design\n",
    tools: "Figma \n Figjam",
    intro: "Make the last mile easier",
  },
];

export default PageTemplatesIntroData;
