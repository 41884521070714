import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import PortfolioCard from "../components/PortfolioCard";
import container from "../assets/HomePage/container.webp";
import passioncy from "../assets/HomePage/passioncy.webp";
import Mockup1 from "../assets/HomePage/Mockup1.webp";
import "../components/PortfolioCardStyles.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import oppo from "../assets/Orelax/cover2.png";
import cover from "../assets/1214/cover2.webp";
import roots from "../assets/roots2.png";
import oculus from "../assets/oculus.png";
import textale from "../assets/HomePage/textale.webp";

const PortfolioCardData = [
  {
    imgsrc: container,
    text: "Logistics SaaS Platform | UX Design",
    title: "DrayEasy",
    link: "/Drayeasy",
  },
  {
    imgsrc: passioncy,
    text: "SaaS Platform | UX Design | Front-end Development",
    title: "Passioncy",
    link: "/Passioncy",
  },
  {
    imgsrc: textale,
    text: "Marketing | User Research",
    title: "Textale User Research",
    link: "/Textale",
  },
  {
    imgsrc: Mockup1,
    text: "Mobile App | UX Design",
    title: "Roversea",
    link: "/Roversea",
  },
  {
    imgsrc: oppo,
    text: "Internship UX Project",
    title: "O Relax",
    link: "/Orelax",
  },
  {
    imgsrc: cover,
    text: "Web Design",
    title: "1214 Music Club",
    link: "/1214",
  },
  {
    imgsrc: roots,
    text: "Mobile App | UX Design",
    title: "Roots Connected",
    link: "https://cattribe-uxp.framer.website/",
  },
  {
    imgsrc: oculus,
    text: "VR Game | Unity Development",
    title: "VR Escape Room",
    link: "https://siqi.webflow.io/vr-escape-room",
  },
];

const Portfolio = (props) => {
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Portfolio", // Replace with your custom path
      title: "Portfolio", // Replace with your custom title
    });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="portfolio-grid">
          {PortfolioCardData.map((v, i) => {
            return (
              <PortfolioCard
                imgsrc={v.imgsrc}
                title={v.title}
                text={v.text}
                id={v.id}
                link={v.link}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
