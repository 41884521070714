import React from "react";
import "./PageTemplatesStyles.css";
import "../index.css";

const PageTemplatesIntro = (props) => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
      className="template-global container"
    >
      <h1>{props.title}</h1>
      <p
        style={{
          textAlign: "center",
        }}
      >
        {props.intro}
      </p>
      {props.headimg && (
        <img src={props.headimg} className="heading-image" alt="1"></img>
      )}

      <div class="project-info">
        <div>
          <h4>Project Type</h4>
          <p className="new-line">{props.projectType}</p>
        </div>
        <div>
          <h4>Team</h4>
          <p className="new-line">{props.team}</p>
        </div>
        <div>
          <h4>My Role</h4>
          <p className="new-line">{props.role}</p>
        </div>
        <div>
          <h4>Contribution</h4>
          <p className="new-line">{props.contribution}</p>
        </div>
        <div>
          <h4>Tool</h4>
          <p className="new-line">{props.tools}</p>
        </div>
      </div>

      {props.headimg2 && (
        <img src={props.headimg2} className="heading-image2" alt="2"></img>
      )}

      {props.problemStatement && (
        <div className="container">
          <section className="template">
            <h2>Overview</h2>
            <hr className="separator"></hr>
            <h3>Problem Statement</h3>

            <p>{props.problemStatement}</p>

            {props.solution1 && (
              <h3 style={{ color: "var(--color)" }}>Solution</h3>
            )}

            {props.solution1 && (
              <div className="solution-div">
                <img
                  src={props.solutionImgsrc1}
                  className="solutionImg"
                  alt="solutionImg"
                  loading="lazy"
                ></img>
                <div className="solution-text">
                  <h3>{props.solutionTitle1}</h3>
                  <br></br>
                  <p>{props.solution1}</p>
                </div>
              </div>
            )}

            {props.solution2 && (
              <div className="solution-div">
                <img
                  src={props.solutionImgsrc2}
                  className="solutionImg"
                  alt="solutionImg"
                  loading="lazy"
                ></img>
                <div className="solution-text">
                  <h3>{props.solutionTitle2}</h3>
                  <br></br>
                  <p>{props.solution2}</p>
                </div>
              </div>
            )}

            {props.solution3 && (
              <div className="solution-div">
                <img
                  src={props.solutionImgsrc3}
                  className="solutionImg"
                  alt="solutionImg"
                  loading="lazy"
                ></img>
                <div className="solution-text">
                  <h3>{props.solutionTitle3}</h3>
                  <br></br>
                  <p>{props.solution3}</p>
                </div>
              </div>
            )}

            {props.solution4 && (
              <div className="solution-div">
                <img
                  src={props.solutionImgsrc4}
                  className="solutionImg"
                  alt="solutionImg"
                  loading="lazy"
                ></img>
                <div className="solution-text">
                  <h3>{props.solutionTitle4}</h3>
                  <br></br>
                  <p>{props.solution4}</p>
                </div>
              </div>
            )}
          </section>
        </div>
      )}
      <div style={{ marginTop: "4rem" }}></div>
    </div>
  );
};

export default PageTemplatesIntro;
