import React from 'react'
import '../index.css';
import './LongCardStyles.css';


const LongCard = (props) => {

  return (

<section className="container card-project card-box">
  <div id="img1" className="card-image">
    <img src={props.imgsrc} alt="Thumbnail" />
  </div>
  <div id="txt1" className="card-text">
    <p>{props.heading}</p>
    <h2 id="project-title">{props.title}</h2>
    <p className="red-text">{props.skills}</p>
    <a className="btn-1" href="./index.html">
      <svg
        t={1688537279441}
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        p-id={3275}
        width={32}
        height={32}
      >
        <path
          d="M724.48 521.728c-1.8432 7.7824-5.7344 14.848-11.3664 20.48l-341.9136 342.016c-16.6912 16.6912-43.7248 16.6912-60.3136 0s-16.6912-43.7248 0-60.3136L622.6944 512 310.8864 200.0896c-16.6912-16.6912-16.6912-43.7248 0-60.3136 16.6912-16.6912 43.7248-16.6912 60.3136 0l341.9136 341.9136c10.8544 10.8544 14.6432 26.112 11.3664 40.0384z"
          fill="#000000"
          p-id={3276}
        />
      </svg>
    </a>
  </div>
  <a href={props.link} className="clickable"> </a>
</section>

  )
}

export default LongCard