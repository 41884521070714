import cover from "../assets/Orelax/cover.jpg";
import cover2 from "../assets/1214/cover.webp";
import roots from "../assets/roots.png";
import vr1 from "../assets/VR/vr1.png";

const SquareCardData = [
  {
    imgsrc: cover,
    title: "O Relax",
    type: "App Design",
    description:
      "Internship project at Oppo. A feature aims to relax and distress people",
    link: "/Orelax",
  },
  {
    imgsrc: cover2,
    title: "1214 Music Club",
    type: "Web Design",
    description:
      "I lead the team to develop the official websites of an asian music club",
    link: "/1214",
  },
  {
    imgsrc: roots,
    title: "Roots Connected",
    type: "App Design | UX Design",
    description: "An app designed to connect immigrants to their culture roots",
    link: "https://cattribe-uxp.framer.website/",
  },
  {
    imgsrc: vr1,
    title: "VR Escape Room",
    type: "Game Design | VR Development",
    description: "A VR escape game designed and developed with Unity",
    link: "https://siqi.webflow.io/vr-escape-room",
  },
];
export default SquareCardData;
