import React, { useEffect, useRef } from "react";
import "./ComparisonStyles.css";

const Comparison = (props) => {
  const containerRef = useRef(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const slider = sliderRef.current;

    const handleInputChange = (e) => {
      container.style.setProperty("--position", `${e.target.value}%`);
    };

    slider.addEventListener("input", handleInputChange);

    return () => {
      slider.removeEventListener("input", handleInputChange);
    };
  }, []);

  return (
    <div id="comparison" className="container comparison" ref={containerRef}>
      <div className="image-container">
        <img
          className="image-before slider-image"
          alt="coder"
          src={props.first}
        />
        <img
          className="image-after slider-image"
          alt="designer"
          src={props.second}
        />
      </div>
      <input
        type="range"
        min={0}
        max={100}
        defaultValue={50}
        aria-label="Percentage of before photo shown"
        className="slider"
        ref={sliderRef}
      />
      <div className="slider-line" aria-hidden="true" />
      <div className="slider-button" aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="128"
            y1="40"
            x2="128"
            y2="216"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <line
            x1="96"
            y1="128"
            x2="16"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="48 160 16 128 48 96"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
          <line
            x1="160"
            y1="128"
            x2="240"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="208 96 240 128 208 160"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
        </svg>
      </div>
    </div>
  );
};

export default Comparison;
