import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SideNavbar from "../components/SideNavbar";

import "../index.css";
import "./Pages.css";
import PageTemplatesIntro from "../components/PageTemplatesIntro";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";
import process from "../assets/textale/process.png";
import purpose from "../assets/textale/purpose.png";
import category from "../assets/textale/category.png";
import example from "../assets/textale/25.png";
import result from "../assets/textale/3.png";
import rawdata from "../assets/textale/rawdata.png";
import gpt from "../assets/textale/gpt.png";

import demographic from "../assets/textale/demographic.png";
import attribuly from "../assets/textale/attribuly.png";
import card from "../assets/textale/card.webp";

const Textale = () => {
  const templateDataTextale = PageTemplatesIntroData[3];
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/Textale", // Replace with your custom path
      title: "Textale", // Replace with your custom title
    });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="textale">
        <SideNavbar />
      </div>
      <PageTemplatesIntro
        title={templateDataTextale.title}
        headimg={templateDataTextale.headimg}
        projectType={templateDataTextale.projectType}
        role={templateDataTextale.role}
        period={templateDataTextale.period}
        team={templateDataTextale.team}
        intro={templateDataTextale.intro}
        contribution={templateDataTextale.contribution}
        tools={templateDataTextale.tools}
      />
      <div className="container template-global textale">
        <section>
          <h2> Overview </h2>
          <hr className="separator"></hr>
          <h3>Why doing the user research?</h3>
          <p>
            Textale, a recently founded small business, specializes in producing
            premium t-shirts and high-quality clothing. Since its establishment
            last year, the company has successfully received close to 2,000
            orders. As the only one specialized in UX field, my primary task is
            to lead the team to develop a standardized approach for constructing
            a user persona repository.
          </p>
          <img src={purpose} alt=""></img>

          <h3> Challenges </h3>
          <p>
            As the figure shows, a large amount of the raw data is "N/A", which
            means it is null or non-valuable. How to get valuable information
            from all the consumers purchasing data is crucial and challenging.
          </p>
          <img src={rawdata} className="image-4rem" alt=""></img>
          <h3> Solution </h3>
          <p>
            We categorized dozens of key users in terms of 5 age groups and 2
            genders. Every key user has colored labels which reprensents
            his/her/their potential consumption motivation. Every key user also
            has labels of his/her/their lifestyle and key features. Personas are
            generated by "who, where, why, how, buy" for each age group.
          </p>
        </section>
      </div>
      <img src={result} className="image-4rem" alt=""></img>
      <div className="container template-global textale">
        <section>
          <h3> Impact </h3>
          <p>
            {" "}
            This was added to the Textale business plan, and I presented the
            methodology and the results of my research on personas in investor
            meetings.
          </p>
          <h2>Roadmap</h2>
          <hr className="separator"></hr>
          <h3>{}</h3>
          <p>
            The first step is to wash the data and get the valuable ones. After
            obtaining effective user data, summarize the user persona.
          </p>
          <img src={process} alt=""></img>
          <h2>Methodology</h2>
          <hr className="separator"></hr>
          <h3>Categorize Users</h3>
          <p>
            We use Attribuly, a CMS management platform to document the users'
            buying information, including gender, age, location, social media,
            actions while browsing our shop pages.
          </p>
          <img src={attribuly} className="image-4rem" alt=""></img>

          <h3>Users' Demographic Information</h3>
          <p>
            We look up to all the public social media of the user, and label his
            demographic information including location, profession and martial
            status. We also try to find his hobbies and life style as they could
            be related to our products. For example, people love outdoor
            activities more likely to buy our water-proof shirts.
          </p>
          <img src={demographic} className="image-4rem" alt=""></img>

          <h3>Predict Users' Category</h3>
          <img src={category} alt=""></img>

          <h3>Generate User's Card</h3>
          <p>
            After collecting all the information, I suggest to use ChatGPT to
            generate user's possible consuming value, the requirement to our
            products and potential marketing platform. The reason is everyone in
            our group has his subjective opinion or understanding of the user,
            and to use ChatGPT to generate these anticipation could make results
            more stable and standarlized. Here is our standarlized prompt after
            multiple tests:
          </p>
          <img src={gpt} style={{ margin: "2rem 0" }} alt=""></img>
          <p> Below is an example key user card we use ChatGPT to get.</p>
          <img src={card} style={{ margin: "2rem 0" }} alt=""></img>

          <h3>Summerizing</h3>
          <p>
            We create a small card for each key user card and put them all
            together. The team anaylsed the chart and generate detailed persona.
          </p>
          <img src={result} className="image-4rem" alt=""></img>
          <p>Here is an example of 25-34 age group</p>
          <img src={example} className="image-4rem" alt=""></img>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Textale;
