import React from "react";
import "./SquareCardStyles.css";

const SquareCard = (props) => {
  return (
    <div className="project-container">
      <a href={props.link} className="link-no-underline2">
        <img className="project-item-image" src={props.imgsrc} alt="" />
        <div className="item-text">
          <h2>{props.title}</h2>
          <h4 style={{ fontWeight: "400" }} className="middle-text red-text">
            {props.type}
          </h4>
          <p>
            {props.description}
            <br />
            <br />
          </p>
        </div>
      </a>
    </div>
  );
};

export default SquareCard;
