import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import PageTemplatesIntroData from "../components/PageTemplatesIntroData";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import video1214 from "../assets/1214/1214video.mp4";

const Onetwoonefour = () => {
  const templateData1214 = PageTemplatesIntroData[4];
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/1214", // Replace with your custom path
      title: "1214", // Replace with your custom title
    });
  }, []);
  return (
    <div>
      <Navbar />

      <div className="template-global">
        <h1>{templateData1214.title}</h1>
        <p>{templateData1214.intro}</p>
        <video
          autoPlay
          loop
          muted
          style={{
            marginTop: "4rem",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={video1214} type="video/mp4"></source>
        </video>

        <div
          className="container"
          style={{
            alignItems: "start",
            marginTop: "4rem",
          }}
        >
          <section className="template">
            <p>
              <b>Project Type</b> / {templateData1214.projectType}
            </p>
            <p>
              <b>Role</b> / {templateData1214.role}
            </p>
            <p>
              <b>Team</b> / 1 Designer, 1 developer,
            </p>
            <p>
              <b>Period</b> / {templateData1214.period}
            </p>
          </section>
        </div>
      </div>

      <div className="container">
        <section className="template">
          <h2
            style={{
              marginTop: "2rem",
            }}
          >
            Problem Statement
          </h2>

          <p
            style={{
              marginTop: "2rem",
              fontSize: "125%",
              lineHeight: "2.5rem",
            }}
          >
            {templateData1214.problemStatement}
          </p>

          <h2
            style={{
              marginTop: "2rem",
            }}
          >
            Solution
          </h2>
          <a
            style={{
              marginTop: "2rem",
              fontSize: "125%",
              lineHeight: "2.5rem",
            }}
            href="https://www.1214-ent.com
            "
          >
            www.1214-ent.com
          </a>
          <h2
            style={{
              marginTop: "2rem",
            }}
          >
            Impact
          </h2>
          <p
            style={{
              marginTop: "2rem",
              fontSize: "125%",
              lineHeight: "2.5rem",
            }}
          >
            The 1214club official website has achieved remarkable success by
            hosting a series of highly successful performances, with each show
            selling more than 100 tickets, attracting a substantial and
            enthusiastic audience.
          </p>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Onetwoonefour;
