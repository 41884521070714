import React from "react";
import Home from "./routes/Home";
import About from "./routes/About";
import Resume from "./routes/Resume";

import Portfolio from "./routes/Portfolio";
import Roversea from "./routes/Roversea";
import Orelax from "./routes/Orelax";
import Passioncy from "./routes/Passioncy";
import Drayeasy from "./routes/Drayeasy";
import Onetwoonefour from "./routes/1214";
import OneDeal from "./routes/OneDeal";

import { Route, Routes } from "react-router-dom";
import Textale from "./routes/Textale";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Portfolio" element={<Portfolio />} />
      <Route path="/Resume" element={<Resume />} />
      <Route path="/About" element={<About />} />
      <Route path="/Roversea" element={<Roversea />} />
      <Route path="/Orelax" element={<Orelax />} />
      <Route path="/Passioncy" element={<Passioncy />} />
      <Route path="/Drayeasy" element={<Drayeasy />} />
      <Route path="/Textale" element={<Textale />} />
      <Route path="/1214" element={<Onetwoonefour />} />
      <Route path="/OneDeal" element={<OneDeal />} />
    </Routes>
  );
}

export default App;
