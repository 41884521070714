import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./AboutStyles.css";
import map from "../assets/map.png";
import me from "../assets/me.webp";
import portrait from "../assets/self-portrait.webp";

import ipod from "../assets/ipod.webp";
import river from "../assets/river.webp";
import duck from "../assets/duck.webp";
import suede from "../assets/suede.webp";
import tokyo from "../assets/tokyo.webp";
import video from "../assets/video.MOV";

import arch1 from "../assets/arch1.webp";
import arch2 from "../assets/arch2.webp";

import { AiOutlineMail, AiFillLinkedin } from "react-icons/ai";
import { BiLinkAlt } from "react-icons/bi";

const About = () => {
  useEffect(() => {
    ReactGA.initialize("G-0S4YWNDX2G");

    // Send pageview event with custom path and title
    ReactGA.send({
      hitType: "pageview",
      page: "/About", // Replace with your custom path
      title: "About", // Replace with your custom title
    });
  }, []);
  return (
    <div className="about">
      <Navbar />
      <div className="container" style={{ marginTop: "4rem" }}>
        <h1>I'm Siqi</h1>
        <h4>Welcome to my corner of Internet</h4>
        <img
          src={portrait}
          alt=""
          style={{ width: "50%", height: "50%" }}
        ></img>
        <div
          className="about-grid"
          style={{ marginTop: "4rem", marginBottom: "4rem" }}
        >
          {" "}
          {/* Item 1 content */}
          <div
            id="about-grid-item-1"
            className="about-grid-item"
            style={{ padding: "2rem" }}
          >
            <h3>A little bit about me</h3>
            <p>
              I'm Siqi Chen, a UX engineer, based in Austin. I've always been
              passionate about technology and its potential to serve people and
              business. I find it incredibly satisfying to create digital
              experiences that are both functional and enjoyable to use, and I
              believe that knowing how to code and design are equally important
              to create a better user experience.
            </p>
          </div>
          {/* Item 2 content */}
          <div id="about-grid-item-2" className="about-grid-item">
            <img src={me} alt=""></img>
          </div>
          {/* Item 3 content */}
          <div id="about-grid-item-3" className="about-grid-item">
            <img src={map} alt="map"></img>
            <p>Austin, TX</p>
          </div>
          {/* Item 4 content */}
          <div
            id="about-grid-item-4"
            className="about-grid-item"
            style={{ padding: "2rem" }}
          >
            <h3>Contact</h3>
            <p>
              Drop me an email or connect me on LinkedIn if you like what you
              see! I'm currently open to opportunities.
            </p>
            <a
              href="mailto:siqichen@utexas.edu"
              style={{
                marginTop: "2rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <AiOutlineMail
                size={20}
                style={{ color: "#000", marginRight: "0.5rem" }}
              />
              siqichen@utexas.edu
            </a>
            <a
              href="https://www.linkedin.com/in/51q1ch3n"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <AiFillLinkedin
                size={20}
                style={{ color: "#000", marginRight: "0.5rem" }}
              />
              LinkedIn
            </a>
          </div>
          {/* Item 5/6 content */}
          <div className="about-subgrid-row">
            <div id="about-grid-item-5">
              <h3>Architecture</h3>
              <p style={{ marginTop: "1rem" }}>
                I have a degree of Architecture Design at the Univeristy of
                Liverpool. So...I guess I am good at design!
              </p>

              <a
                href="https://www.behance.net/qiqiss"
                style={{
                  marginTop: "2.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <BiLinkAlt
                  size={20}
                  style={{ color: "#000", marginRight: "0.5rem" }}
                />
                Click here to view my architecture portfolio{" "}
              </a>
            </div>
            <div id="about-grid-item-6">
              <img src={arch2} alt=""></img>
            </div>
          </div>
          {/* Item 7 content */}
          <div id="about-grid-item-7" className="about-grid-item">
            <img src={arch1} alt=""></img>
          </div>
          <div>
            {" "}
            <h3>Mood</h3>
          </div>
          {/* Item 8 content */}
          <div id="about-grid-item-8" className="about-grid-item">
            <video
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src={video} type="video/mp4"></source>
            </video>
          </div>
          {/* Item 9 content */}
          <div id="about-grid-item-9" className="about-grid-item">
            <img src={river} alt=""></img>
          </div>
          {/* Item 10 content */}
          <div id="about-grid-item-10" className="about-grid-item">
            <img src={ipod} alt=""></img>
          </div>
          {/* Item 11 content */}
          <div id="about-grid-item-11" className="about-grid-item">
            <img src={suede} alt=""></img>
          </div>
          {/* Item 12 content */}
          <div id="about-grid-item-12" className="about-grid-item">
            <img src={tokyo} alt=""></img>
          </div>
          {/* Item 13 content */}
          <div id="about-grid-item-13" className="about-grid-item">
            <img src={duck} alt=""></img>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
