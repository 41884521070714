import React, { useEffect, useState } from "react";
import "./SideNavbar.css"; // 导入 CSS 样式

function SideNavbar() {
  const [headings, setHeadings] = useState([]);
  const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0);

  useEffect(() => {
    const h2Elements = document.querySelectorAll("h2");
    const headingsArray = Array.from(h2Elements).map((h2) => h2.textContent);
    setHeadings(headingsArray);
  }, []);

  const scrollToHeading = (index) => {
    const h2Elements = document.querySelectorAll("h2");
    const targetHeading = h2Elements[index];
    targetHeading.scrollIntoView({ behavior: "smooth" });
  };

  // 监听滚动事件，更新当前标题索引
  useEffect(() => {
    const handleScroll = () => {
      const h2Elements = document.querySelectorAll("h2");
      const windowHeight = window.innerHeight;

      for (let i = 0; i < h2Elements.length; i++) {
        const heading = h2Elements[i];
        const rect = heading.getBoundingClientRect();

        if (rect.top >= 0 && rect.top <= windowHeight / 2) {
          setCurrentHeadingIndex(i);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="sidebar">
        {headings.map((heading, index) => (
          <div
            key={index}
            className={`sidebar-item ${
              index === currentHeadingIndex ? "active" : ""
            }`}
            onClick={() => scrollToHeading(index)}
          >
            {heading}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideNavbar;
