import React from "react";
import "./FooterStyles.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        @Designed in <b>Figma</b>, developed with <b>React</b> and deployed on{" "}
        <b>Netlify</b> by myself.
      </p>
    </footer>
  );
};

export default Footer;
