import React from "react";
import "./PortfolioCardStyles.css";

const PortfolioCard = (props) => {
  return (
    <div>
      <a href={props.link} className="grid-item-link">
        <div className="grid-item" id={props.id}>
          <img className="grid-img" src={props.imgsrc} alt=""></img>
          <h3>{props.title}</h3>
          <p>{props.text}</p>
        </div>
      </a>
    </div>
  );
};

export default PortfolioCard;
